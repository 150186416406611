header,
footer .footer-nav,
.footer-legal,
.footer-certif,
#home-hero,
button,
.ariane,
.slick-dots,
.tab-control,
.slider-nav-link,
.exergue-deco,
.paragraph--type--video,
.paragraph--type--slider,
form,
.side-content,
.fancybox-container,
.page-top-controls,
.main-footer,
.acces-rapide-pop,
.pagination,
.side-bloc-plus,
.side-bloc-fichiers,
.side-ctas,
.side-blocs-controls,
.filters {
  display: none !important;
}

a:not([data-fancybox])::after {
  content: " ( " attr(href) " ) ";
}

.page-top-title,
.home-actu-item {
  background: none !important;
}

.slick-slide {
  left: auto !important;
  top: auto !important;
  opacity: 1 !important;
}

table {
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  text-align: center;
  font-weight: 400;
  border-spacing: 0;
}
table tr th {
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-weight: 700;
  padding: 1.3rem 0.7rem;
}
table tr td {
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 1.3rem 0.7rem;
}
table tr td:first-of-type {
  font-weight: 700;
}

h1 {
  font-size: 2em;
}

.page-top-inner {
  background: none !important;
}

.accordeon-content {
  height: auto !important;
}

[class$=-item] {
  margin-top: 30px;
}

[class$=-title] {
  display: block;
}